import React, { Component } from 'react';
import Video from 'twilio-video';

class Participant extends Component {

  componentDidMount() {
    Video.connect(this.props.state.token,
      {
        name: this.props.state.roomCode,
        audio: true,
        video: true
      }
    ).then(room => {

      this.props.joinRoom(room)

      room.participants.forEach(participant => this.participantConnected(participant));

      room.on('participantConnected', participant => this.participantConnected(participant));

      const localVideoTrack = Array.from(room.localParticipant.videoTracks.values())[0].track;
      this.attachTrack(localVideoTrack, this.refs.localMedia);


    }, error => {
      console.error(`Unable to connect to Room: ${error.message}`);
    });
  }

  participantConnected(participant) {
    participant.tracks.forEach(publication => {
      if (publication.isSubscribed) {
        const remoteMedia = this.refs.remoteMedia
        remoteMedia.appendChild(publication.track.attach())
      }
    });

    participant.on('trackSubscribed', track => {
      const remoteMedia = this.refs.remoteMedia
      remoteMedia.appendChild(track.attach())
    });
  }

  attachTrack(track, container) {
    container.appendChild(track.attach());
  }

  render() {
    return (
      <div style={{ "height": "89.5%" }}>
        <div className="h-100 d-flex">
          <div className="col-md-2 position-relative">
            <div className="w-100 position-absolute text-white text-center" style={{ "paddingLeft": "15px", "left": 0, "bottom": 10 }}>
              {
                !this.props.state.muteAudioLocal ?
                  <div><i className="fa fa-microphone"></i> { this.props.state.identity }</div>
                :
                  <div><i className="fa fa-microphone-slash"></i> { this.props.state.identity }</div>
              }
            </div>
          </div>
          <div className="col-md-8 text-white local-participant">
            {
              this.props.state.muteVideoLocal && !this.props.state.localShareScreen ?
                <div className="defaultProfile" style={{ "background": `#${this.props.state.color}` }}>{ this.props.state.initial }</div>
              : ''
            }
            {
              this.props.state.localShareScreen ?
                <div className="share-screen" ref="localScreen" style={{ "width": "100%", "height": "100%", "objectFit": "cover" }}></div>
                : ''
            }
            {
              this.props.state.muteVideoLocal || this.props.state.localShareScreen ?
                <div ref="remoteMedia" style={{ "width": "100%", "height": "100%", "objectFit": "cover", "display": "none" }} ></div>
              :
                <div ref="remoteMedia" style={{ "width": "100%", "height": "100%", "objectFit": "cover" }} ></div>
            }
          </div>
          <div className="col-md-2 position-relative">
            <div className="w-100 position-relative" style={{ "height": "100%", "left": 0, "bottom": "10px" }}>
              {
                this.props.state.muteVideoLocal ?
                  <div className="defaultProfileParticipant" style={{ "background": `#${this.props.state.color}` }}>{ this.props.state.initial }</div>
                : ''
              }
              {
                this.props.state.localShareScreen ?
                  <div className="remote-participant" ref="remoteScreen" style={{ "width": "100%", "display": "none" }}></div>
                  : ''
              }
              {
                this.props.state.muteVideoLocal || this.props.state.localShareScreen ?
                  <div className="remote-participant" ref="localMedia" style={{ "width": "100%", "display": "none" }}></div>
                :
                  <div className="remote-participant" ref="localMedia" style={{ "width": "100%" }}></div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }

} export default Participant;
