import React, { Component } from 'react'
import '../assets/chat.css'
import axios from 'axios'

class ChatBar extends Component {

  constructor(props) {
    super(props)
    this.generateToken = this.generateToken.bind(this)
    this.connectChat = this.connectChat.bind(this)
    this.getChannel = this.getChannel.bind(this)
    this.sendMessage = this.sendMessage.bind(this)
    this.joinChannel = this.joinChannel.bind(this)
    this.uploadFile = this.uploadFile.bind(this)
    this.clickToUpload = this.clickToUpload.bind(this)
  }

  async connectChat() {
    const Chat = require("twilio-chat")
    const client = await Chat.Client.create(this.props.state.token)
    const payload = {
      identity: this.props.state.identity,
      room_code: this.props.state.room_code
    }

    client.on("tokenAboutToExpire", async () => {
      const token = await this.generateToken(payload)
      client.updateToken(token)
    })

    client.on("tokenExpired", async () => {
      const token = await this.generateToken(payload)
      client.updateToken(token)
    })

    client.on("channelJoined", async (channel) => {
      const messages = await channel.getMessages()
      this.props.updateMessage(messages)
    })

    this.getChannel(client)
  }

  async getChannel(client) {

    if (this.props.state.roomCode) {
      try {
        const channel = await client.getChannelByUniqueName(this.props.state.roomCode)

        this.props.setChannel(channel)
        await this.joinChannel(channel)

      } catch (e) {
        const channel = await client.createChannel({
          uniqueName: this.props.state.roomCode,
          friendlyName: this.props.state.identity,
        })

        this.props.setChannel(channel)
        await this.joinChannel(channel)
      }
    }
  }

  async joinChannel(channel) {
    if (channel.channelState.status !== "joined") {
      await channel.join()
    }

    channel.on("messageAdded", (messages) => {
      this.props.sendMessage(messages)
    });
  }

  async generateToken(data) {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/join-room`, {
      params : {
        identity: data.identity,
        room_code: data.room_code
      }
    })

    const payload = response.data.data

    this.props.updateToken(payload)

    return payload.token
  }

  async sendMessage(event) {
    event.preventDefault()
    const form = new FormData(event.target)

    this.props.state.channel.sendMessage(form.get('message'));
    this.refs.inputMessage.value = ""
  }

  async uploadFile(event) {
    const formData = new FormData()
    formData.append('file', event.target.files[0])
    await this.props.state.channel.sendMessage(formData);
  }

  async clickToUpload() {
    const media = this.refs.files.click()
  }

  async images(message) {
    const media = await message.media.getContentTemporaryUrl()
    return media
  }

  render() {
    const footerChat = {
      bottom: "0",
      color: "white",
      paddingTop: "15px",
      zIndex: 1000,
      height: "5%",
      width: "100%"
    }

    return (
      <div className="col-lg-3 p-0 h-100">
        <div className="card h-100" style={{ "backgroundColor": "unset" }}>
          <div className="card-header text-white" style={{ "height": "5%" }}>
            <h4 className="card-title"><strong>Chat</strong></h4>
            <span><i className="fa fa-times"></i></span>
          </div>
          <div className="ps-container ps-theme-default ps-active-y" id="chat-content" style={{"height": "90% !important"}}>
            {
              this.props.state.messages.map((message, i) => {
                return message.author === this.props.state.identity ?
                  <div className="media media-chat media-chat-reverse" key={i}>
                    <div className="media-body">
                      {
                        message.contentType === 'image' ?
                          <span>
                            <img src={message.url} style={{ "width": "100%" }} />
                            <p className="meta" style={{ "color": "#000" }}>
                              <time dateTime="2018">{new Date(message.dateCreated.toISOString()).toLocaleString()}</time>
                            </p>
                          </span>
                        : message.contentType === 'pdf' ?
                          <span>
                            <a href={message.url} target="_blank"><p><img src="../pdf-ico.svg" style={{ "width": "20%", "filter": "invert(1)" }} />{ message.media.filename }</p></a>
                            <p className="meta" style={{ "color": "#000" }}>
                              <time dateTime="2018">{new Date(message.dateCreated.toISOString()).toLocaleString()}</time>
                            </p>
                          </span>
                        :
                        <span>
                          <p>{ message.body }</p>
                          <p className="meta" style={{ "color": "#000" }}>
                            <time dateTime="2018">{new Date(message.dateCreated.toISOString()).toLocaleString()}</time>
                          </p>
                        </span>
                      }
                    </div>
                  </div>
                :
                  <div className="media media-chat" key={i}>
                    <div className="media-body">
                      <p>{ message.body }</p>
                      <p className="meta">
                        <time dateTime="2018">{new Date(message.dateCreated.toISOString()).toLocaleString()}</time>
                      </p>
                    </div>
                  </div>
              })
            }
          </div>
          <div className="publisher bt-1 border-light" style={footerChat}>
            <form onSubmit={this.sendMessage} style={{ "width": "100%" }}>
              <input autoComplete="off"
                ref="inputMessage"
                className="publisher-input"
                type="text"
                name="message"
                placeholder="Write something"
              />
            </form>
            <span className="publisher-btn file-group" onClick={this.clickToUpload}>
              <i className="fa fa-paperclip file-browser"></i>
              <input accept="imgae/jpg, image/jpeg, imgae/png, application/pdf" type="file" ref="files" onChange={this.uploadFile}/>
            </span>
            <a className="publisher-btn" href="#" data-abc="true">
              <i className="fa fa-smile"></i>
            </a>
            <a className="publisher-btn text-green" href="#" data-abc="true">
              <i className="fa fa-camera"></i>
            </a>
          </div>
        </div>
      </div>
    )
  }
} export default ChatBar
