import React, { Component } from 'react';

class Sidebar extends Component {

  render() {
    return (
      <div className="col-lg-1 p-0" style={{ "background": "#3868B0" }}>
       <div className="sidebar-nav nav-pills h-100 nav-stacked" id="menu">
        <div style={{ "display": "block" }} className="active">
          <a href="#" style={{ "color": "#fff" }} className="text-center">
            <div className="fa-stack fa-lg w-100 d-block">
              <img src="../headphone.svg"/>
            </div>
            <div className="w-100 d-block">Calling</div>
          </a>
        </div>
       </div>
      </div>
    );
  }
} export default Sidebar;
