import React, { Component } from 'react';
import Video from 'twilio-video';

class MenuBar extends Component {

  constructor(props) {
    super(props)
    this.muteVideoLocal = this.muteVideoLocal.bind(this)
    this.unmuteVideoLocal = this.unmuteVideoLocal.bind(this)
    this.muteAudioLocal = this.muteAudioLocal.bind(this)
    this.unmuteAudioLocal = this.unmuteAudioLocal.bind(this)
    this.shareScreen = this.shareScreen.bind(this)
  }

  async muteVideoLocal() {
    const room = this.props.room
    const initial = await this.getInitialName()

    this.props.muteVideo(initial)

    room.localParticipant.videoTracks.forEach(publication => {
      publication.track.disable();
    });
  }

  async unmuteVideoLocal() {
    const room = this.props.room

    this.props.unmuteVideo()

    room.localParticipant.videoTracks.forEach(publication => {
      publication.track.enable();
    });
  }

  async muteAudioLocal() {
    const room = this.props.room

    this.props.muteAudio()

    room.localParticipant.audioTracks.forEach(publication => {
      publication.track.disable();
    });
  }

  async unmuteAudioLocal() {
    const room = this.props.room

    this.props.unmuteAudio()

    room.localParticipant.audioTracks.forEach(publication => {
      publication.track.enable();
    });
  }

  getInitialName() {
    let identity = this.props.identity
    let initial = ''
    if (identity) {
      initial = identity.charAt(0)
    }

    return initial.toUpperCase()
  }

  async shareScreen() {
    if ( typeof navigator === 'undefined' || !navigator.mediaDevices || !navigator.mediaDevices.getDisplayMedia ) {
      return Promise.reject(new Error('getDisplayMedia is not supported'));
    }

    const stream = await navigator.mediaDevices.getDisplayMedia({
      video: {
        height: 720,
        width: 640
      }
    })

    this.props.shareScreen(stream)

    const track = new Video.LocalVideoTrack(stream.getVideoTracks()[0]);
    const localStream = this.refs.localScreen
    localStream.appendChild(track.attach())
  }

  render() {
    return (
      <div style={{ "height": "11.5%" }}>
        <div className="bg-dark p-4" style={{ "cursor": "pointer" }}>
          <div className="d-flex w-100">
            <div className="col-lg-4">
              <div className="row">
                {
                  !this.props.audio ?
                    <div className="col-md-4 text-center text-white" onClick={this.muteAudioLocal}>
                      <div><i className="fa fa-microphone"></i></div>
                      <div className="pt-1" style={{ "fontSize": "15px" }}>Stop Mic</div>
                    </div>
                  :
                    <div className="col-md-4 text-center text-white" onClick={this.unmuteAudioLocal}>
                      <div><img src="../mute-mic.svg" /></div>
                      <div className="pt-1" style={{ "fontSize": "15px" }}>Start Mic</div>
                    </div>
                }
                {
                  !this.props.video ?
                    <div className="col-md-4 text-center text-white" onClick={this.muteVideoLocal}>
                      <div><i className="fa fa-video-camera"></i></div>
                      <div className="pt-1" style={{ "fontSize": "15px" }}>Stop Video</div>
                    </div>
                  :
                    <div className="col-md-4 text-center text-white" onClick={this.unmuteVideoLocal}>
                      <div><img src="../mute-video.svg" /></div>
                      <div className="pt-1" style={{ "fontSize": "15px" }}>Start Video</div>
                    </div>
                }
              </div>
            </div>
            <div className="col-lg-4 text-white text-center" onClick={this.shareScreen}>
              <div><img src="../upload.svg" style={{ "width": "auto" }} /></div>
              <div className="pt-1"  style={{ "fontSize": "15px" }}>Share Screen</div>
            </div>
            <div className="col-lg-4 text-right">
              <div className="row">
                <div className="col-md-4 text-center text-white" style={{ "marginLeft": "auto" }} onClick={this.chat}>
                  <div><img src="../chat.svg" style={{ "width": "auto" }} /></div>
                  <div className="pt-1"  style={{ "fontSize": "15px" }}>Message</div>
                </div>
                <div className="col-md-4 text-center text-white">
                  <div><img src="../other.svg" style={{ "width": "auto" }} /></div>
                  <div className="pt-1"  style={{ "fontSize": "15px" }}>Other</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
} export default MenuBar;
