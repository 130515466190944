import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/navbar'
import Footer from '../components/footer';
import ChatBar from '../components/chat'
import CallInfo from '../components/call-info'
import MenuBar from '../components/menu-bar'
import Participant from '../components/participant'
import Sidebar from '../components/sidebar'
import VideoHeader from '../components/video-header'
import axios from 'axios'
import '../assets/room.css';

class Room extends Component {

  constructor(props) {
    super(props)

    const localMedia = React.createRef()
    const state = this.props.location.state
    const color = Math.floor(Math.random()*16777215).toString(16);

    this.state = {
      room: null,
      channel: null,
      muteAudioLocal: false,
      muteVideoLocal: false,
      localShareScreen: false,
      localStream: null,
      muteAudioLocal: false,
      muteVideoLocal: false,
      initial: '',
      color: color,
      identity: state.identity,
      token: state.token,
      roomCode: state.roomCode,
      messages: []
    }

    this.leaveRoom = this.leaveRoom.bind(this)
    this.joinRoom = this.joinRoom.bind(this)
    this.muteVideo = this.muteVideo.bind(this)
    this.unmuteVideo = this.unmuteVideo.bind(this)
    this.muteAudio = this.muteAudio.bind(this)
    this.unmuteAudio = this.unmuteAudio.bind(this)
    this.shareScreen = this.shareScreen.bind(this)
    this.sendMessage = this.sendMessage.bind(this)
    this.updateMessage = this.updateMessage.bind(this)
    this.updateToken = this.updateToken.bind(this)
    this.setChannel = this.setChannel.bind(this)

    this.chatBar = React.createRef()
  }

  leaveRoom() {
    this.state.room.disconnect()
    this.state.channel.delete()
    this.props.history.push('/')
  }

  joinRoom(room) {
    this.setState({ room: room })
    this.chatBar.current.connectChat()
  }

  muteAudio() {
    this.setState({ muteAudioLocal: true })
  }

  unmuteAudio() {
    this.setState({ muteAudioLocal: false })
  }

  muteVideo(initial) {
    this.setState({ muteVideoLocal: true, initial: initial })
  }

  unmuteVideo() {
    this.setState({ muteVideoLocal: false, initial : '' })
  }

  shareScreen(stream) {
    this.setState({ localShareScreen: true, localStream: stream })
  }

  sendMessage(newMessage) {

    if (newMessage.type === 'media') {
      newMessage.contentType = 'image'
      if (newMessage.media.contentType === 'application/pdf') {
        newMessage.contentType = 'pdf'
      }

      newMessage.media.getContentTemporaryUrl().then(url => {
        newMessage.url = url
        this.setState({
          messages: !!this.state.messages ? [...this.state.messages, newMessage] : [newMessage],
        });
      })
    }else{
      this.setState({
        messages: !!this.state.messages ? [...this.state.messages, newMessage] : [newMessage],
      });
    }

    console.log(this.state.message);
  }

  updateMessage(messages) {
    this.setState({ messages: messages.items || [] });
  }

  updateToken(payload) {
    this.setState({ token: payload.token, roomCode: payload.room_code, identity: payload.identity })
  }

  setChannel(channel) {
    this.setState({ channel: channel })
  }

  render() {

    return (
      <div style={{ "position": "fixed", "width": "100%", "height": "100%", "overflow": "hidden" }}>
        <CallInfo
          code={this.state.roomCode}
          leaveRoom={this.leaveRoom}
        />
        <div className="d-flex" style={{ "height": "90%" }}>
          <Sidebar />
          <div className="col-lg-8 p-0" style={{ "position": "relative", "background": "#000" }}>
            <VideoHeader leaveRoom={this.leaveRoom} />
            <Participant
              state={this.state}
              joinRoom={this.joinRoom}
            />
            <MenuBar
              room={this.state.room} identity={this.state.identity}
              audio={this.state.muteAudioLocal} video={this.state.muteVideoLocal}
              muteAudio={this.muteAudio} unmuteAudio={this.unmuteAudio}
              muteVideo={this.muteVideo} unmuteVideo={this.unmuteVideo}
              shareScreen={this.shareScreen}
            />
          </div>
          <ChatBar ref={this.chatBar} state={this.state}
            setChannel={this.setChannel} updateMessage={this.updateMessage}
            sendMessage={this.sendMessage}
          />
        </div>
      </div>
    );
  }
} export default Room;
