import React, { Component } from 'react'
import Room from './page/room'
import Home from './page/home'
import JoinRoom from './page/join-room'
import CreateRoom from './page/create-room'
import {
  BrowserRouter as Router,
  Switch,
  Route
}  from 'react-router-dom'


function App() {
    return (
      <Router>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/create-room" component={CreateRoom} />
          <Route path="/join-room" component={JoinRoom} />
          <Route path="/room" component={Room} />
        </Switch>
      </Router>
    )
}

export default App;
