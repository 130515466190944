import React, { Component } from 'react'
import Footer from '../components/footer'
import Navbar from '../components/navbar'
import { Link, Redirect } from 'react-router-dom'
import axios from 'axios'

class JoinRoom extends Component {

  constructor(props) {
    super(props)

    this.state = {
      roomCode: null,
      identity: null,
      token: null
    }

    this.submitHandler = this.submitHandler.bind(this)
    this.generateToken = this.generateToken.bind(this)
  }

  submitHandler(event) {
    event.preventDefault()
    const form = new FormData(event.target)
    this.generateToken(form)
  }

  async generateToken(form) {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/join-room`, null, {
      params : {
        identity: form.get('nickname'),
        room_code: form.get('room_code')
      }
    })

    const payload = response.data.data

    this.setState({
      token: payload.token,
      roomCode: payload.room_code,
      identity: payload.identity
    })

    this.props.history.push({
      pathname: '/room',
      state: this.state
    })
  }

  render() {
    return (
      <div>
        <Navbar/>
        <div className="container-fluid h-100" style={{"paddingTop": "15%"}}>
          <div className="row justify-content-center align-items-center h-100">
            <div className="col col-sm-6 col-md-6 col-lg-4 col-xl-3">
              <form onSubmit={this.submitHandler}>
                <div className="form-group">
                  <input className="form-control form-control-lg" required autoComplete="off" name="nickname" placeholder="Your Name" type="text" />
                </div>
                <div className="form-group">
                  <input className="form-control form-control-lg" required autoComplete="off" name="room_code" placeholder="Enter Room Code" type="text" />
                </div>
                <div className="form-group">
                  <button className="btn btn-lg btn-block" style={{ "backgroundColor": "#61c7b5", "color": "white" }}>Join Room</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
} export default JoinRoom;
