import React, { Component } from 'react';

class VideoHeader extends Component {

  render() {
    return (
      <div className="w-100" style={{ "position": "absolute", "top": 0, "background": "rgba(58, 58, 60, 0.5)", "zIndex": 1 }}>
        <div className="row p-3">
          <div className="col-md-4 text-white">
            <img src="../info.svg"  style={{ "cursor": "pointer" }}/>
          </div>
          <div className="col-md-3 text-white offset-md-1">10:12:00</div>
          <div className="offset-md-3">
            <button onClick={this.props.leaveRoom} className="btn btn-danger btn-sm">Leave Call</button>
          </div>
        </div>
      </div>
    );
  }
} export default VideoHeader;
