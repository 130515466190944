import React, { Component } from 'react';

class Footer extends Component {

  render() {
    const date = new Date
    const footerStyle = {
      position: "fixed",
      left: 0,
      bottom: 0,
      width: "100%",
      backgroundColor: "#2c528b",
      color: "white",
      textAlign: "center",
      paddingTop: "15px",
      zIndex: 1000
    }

    return (
      <footer style={footerStyle}>
        <p>&copy; AlteaCare, {date.getFullYear()}. All Right Reserved</p>
      </footer>
    );
  }
} export default Footer;
