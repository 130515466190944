import React, { Component } from 'react';
import Footer from '../components/footer';
import Navbar from '../components/navbar';
import { Link, Redirect } from 'react-router-dom';

class Home extends Component {

  render() {
    return (
      <div>
        <Navbar/>
        <div className="container-fluid h-100" style={{"paddingTop": "15%"}}>
          <div className="row justify-content-center align-items-center h-100">
            <div className="col col-sm-6 col-md-6 col-lg-4 col-xl-3">
              <div className="form-group">
                <Link to="/create-room" className="btn btn-lg btn-block" style={{ "backgroundColor": "rgb(56 142 191)", "color": "white" }}>Create Room</Link>
              </div>
              <div className="form-group">
                <Link to="/join-room" className="btn btn-lg btn-block" style={{ "backgroundColor": "#61c7b5", "color": "white" }}>Join Room</Link>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
} export default Home;
