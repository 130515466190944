import React, { Component } from 'react';

class CallInfo extends Component {

  render() {
    return (
      <nav className="navbar navbar-expand-sm bg-light navbar-light" style={{ "height": "10%" }}>
        <a className="navbar-brand col-md-5" href="/" onClick={this.props.leaveRoom}>
          <img className="offset-md-2" src="../altea.svg" />
        </a>
        <span className="col-md-7">Room Code : { this.props.code }</span>
      </nav>
    );
  }
} export default CallInfo;
