import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';

class Navbar extends Component {

  render() {
    return (
      <nav className="navbar navbar-expand-sm bg-light navbar-light">
        <Link className="navbar-brand" to="/">
          <img className="offset-md-11" src="../altea.svg" />
        </Link>
      </nav>
    );
  }
} export default Navbar;
